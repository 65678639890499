import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { GreenIndex_Summary } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'
import { DeliveryFiscalYear } from '@enechain/jcex-proto/proto/jcex/bff/shared/model/v1/model_pb'
import { formatYYYYMMdd } from '@enechain/jcex-utils'

import {
  RenewableEnergyAndTrackingStatusModelForCarbonIndex,
  filterWithConvertAllProducts,
} from '~/trader/pages/CarbonIndex/models/productType'
import { Maybe } from '~/trader/types/AdvancedTypes'

export type AveragePriceModel = {
  productType: string
} & Record<string, string>

export type LatestPriceValue = {
  price: string
  changeAmount: string
  changeRate: number
  isNull: boolean
  productType: RenewableEnergyAndTrackingStatusModelForCarbonIndex
  deliveryFiscalYear: string
}

export type LatestPriceModel = {
  productType: string
} & Record<string, LatestPriceValue | string>

export type NFCGreenIndexSummaryModelV2 = {
  averagePrices: AveragePriceModel[]
  latestPrices: LatestPriceModel[]
  latestReferenceDate: string
}

export function convertNFCGreenIndexSummaryModelV2(
  greenIndexSummary: Maybe<GreenIndex_Summary>,
  allProducts: RenewableEnergyAndTrackingStatus[],
  allDeliveryFiscalYear: DeliveryFiscalYear[],
): Maybe<NFCGreenIndexSummaryModelV2> {
  if (greenIndexSummary === undefined) {
    return undefined
  }

  const targetYears = allDeliveryFiscalYear.map((item) => item.year)

  const filteredProductModels = filterWithConvertAllProducts(
    allProducts,
    allDeliveryFiscalYear,
  )

  const averagePrices = filteredProductModels.flatMap((product) => {
    const targetPrices = greenIndexSummary.nfcAveragePrices.filter(
      (averagePrice) =>
        averagePrice.renewableEnergyAndTrackingStatus?.type === product.type &&
        targetYears.includes(averagePrice.deliveryFiscalYear?.year ?? 0),
    )

    const yearPrices = Object.fromEntries(
      targetPrices.map((targetPrice) => [
        targetPrice.deliveryFiscalYear?.year.toString() ?? '',
        targetPrice.price !== undefined
          ? Number(targetPrice.price).toLocaleString()
          : '-',
      ]),
    )

    return {
      productType: product.displayName,
      ...yearPrices,
    }
  })

  const latestPrices = filteredProductModels.flatMap((product) => {
    const targetPrices = greenIndexSummary.latestNfcIndexPrices.filter(
      (indexPrice) =>
        indexPrice.renewableEnergyAndTrackingStatus?.type === product.type &&
        targetYears.includes(indexPrice.deliveryFiscalYear?.year ?? 0),
    )

    const yearPrices = Object.fromEntries(
      targetPrices.map((targetPrice) => [
        targetPrice.deliveryFiscalYear?.year.toString() ?? '',
        {
          price:
            targetPrice.indexValue?.price !== undefined
              ? Number(targetPrice.indexValue.price).toLocaleString()
              : '-',
          changeAmount:
            targetPrice.indexValue?.changeAmount !== undefined
              ? Number(targetPrice.indexValue.changeAmount).toLocaleString()
              : '0',
          changeRate: targetPrice.indexValue?.changeRate ?? 0,
          isNull: targetPrice.indexValue === undefined,
        },
      ]),
    )

    return {
      productType: product.displayName,
      ...yearPrices,
    }
  })

  return {
    averagePrices,
    latestPrices,
    latestReferenceDate:
      greenIndexSummary.latestReferenceDate !== undefined
        ? formatYYYYMMdd(greenIndexSummary.latestReferenceDate.toDate(), '/')
        : '',
  }
}
