import {
  AccessorFn,
  Table,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { t } from 'i18next'

import { Spacing } from '@enechain/ecloud-designsystem'

import {
  AveragePriceModel,
  LatestPriceModel,
  NFCGreenIndexSummaryModelV2,
} from '~/trader/pages/CarbonIndex/models/indexSummary/nfcV2'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Params = {
  data: Maybe<NFCGreenIndexSummaryModelV2>
  displayDeliveryFiscalYears: number[]
}

type Result = {
  averagePriceTable: Table<AveragePriceModel>
  latestPriceTable: Table<LatestPriceModel>
}

export function usePriceTable({
  data,
  displayDeliveryFiscalYears,
}: Params): Result {
  const averageColumnHelper = createColumnHelper<AveragePriceModel>()
  const latestColumnHelper = createColumnHelper<LatestPriceModel>()

  const averageColumns = [
    averageColumnHelper.accessor('productType', {
      cell: (info) => info.getValue(),
      header: t('domain:model.carbon_index.column.product'),
      enableSorting: false,
    }),
    ...displayDeliveryFiscalYears.sort().map((year) => {
      return averageColumnHelper.accessor(
        year.toString() as unknown as AccessorFn<AveragePriceModel>,
        {
          cell: (info) => info.getValue(),
          header: year.toString(),
          enableSorting: false,
          meta: {
            headerStyle: {
              textAlign: 'right',
            },
            cellStyle: {
              textAlign: 'right',
              width: '15%',
            },
          },
        },
      )
    }),
  ]

  const latestColumns = [
    latestColumnHelper.accessor('productType', {
      cell: (info) => info.getValue(),
      header: t('domain:model.carbon_index.column.product'),
      enableSorting: false,
    }),
    ...displayDeliveryFiscalYears.sort().map((year) => {
      return latestColumnHelper.accessor(
        year.toString() as unknown as AccessorFn<LatestPriceModel>,
        {
          cell: (info) => info.getValue(),
          header: year.toString(),
          enableSorting: false,
          meta: {
            headerStyle: {
              textAlign: 'right',
            },
            cellStyle: {
              textAlign: 'right',
              width: '15%',
              paddingY: Spacing[2],
            },
          },
        },
      )
    }),
  ]

  const averagePriceTable = useReactTable({
    columns: averageColumns,
    data: data?.averagePrices ?? [],
    getCoreRowModel: getCoreRowModel(),
  })

  const latestPriceTable = useReactTable({
    columns: latestColumns,
    data: data?.latestPrices ?? [],
    getCoreRowModel: getCoreRowModel(),
  })

  return {
    averagePriceTable,
    latestPriceTable,
  }
}
