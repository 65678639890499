import { Product } from '@enechain/jcex-proto/proto/jcex/bff/jcredit/model/v1/model_pb'
import { RenewableEnergyAndTrackingStatus } from '@enechain/jcex-proto/proto/jcex/bff/nfc/model/v1/model_pb'
import { GreenIndex } from '@enechain/jcex-proto/proto/jcex/bff/report/v1/model_pb'

import {
  JCreditGreenIndexGraphModel,
  convertJCreditGreenIndexGraphModel,
} from '~/trader/pages/CarbonIndex/models/graphData/jCredit'
import {
  NFCGreenIndexGraphModel,
  convertNFCGreenIndexGraphModel,
} from '~/trader/pages/CarbonIndex/models/graphData/nfc'
import {
  JCreditGreenIndexSummaryModel,
  convertJCreditGreenIndexSummaryModel,
} from '~/trader/pages/CarbonIndex/models/indexSummary/jCredit'
import {
  NFCGreenIndexSummaryModel,
  convertNFCGreenIndexSummaryModel,
} from '~/trader/pages/CarbonIndex/models/indexSummary/nfc'
import {
  NFCGreenIndexSummaryModelV2,
  convertNFCGreenIndexSummaryModelV2,
} from '~/trader/pages/CarbonIndex/models/indexSummary/nfcV2'
import { GreenIndexFilter } from '~/trader/pages/CarbonIndex/types/filterGreenIndex'
import { Maybe } from '~/trader/types/AdvancedTypes'

export type GreenIndexModel = {
  nfc: {
    summary: Maybe<NFCGreenIndexSummaryModel>
    summaryV2: Maybe<NFCGreenIndexSummaryModelV2>
    graph: NFCGreenIndexGraphModel
    allProducts: RenewableEnergyAndTrackingStatus[]
  }
  jCredit: {
    summary: Maybe<JCreditGreenIndexSummaryModel>
    graph: JCreditGreenIndexGraphModel
    allProducts: Product[]
  }
}

export function convertGreenIndexModel(
  greenIndex: Maybe<GreenIndex>,
  filter: GreenIndexFilter,
): Maybe<GreenIndexModel> {
  if (greenIndex === undefined) {
    return undefined
  }

  const filterNFCAllProducts = greenIndex.nfcAllProducts.filter((product) =>
    filter.nfcProductTypes.includes(product.type),
  )
  const filterNFCAllDeliveryFiscalYear =
    greenIndex.nfcAllDeliveryFiscalYear.filter((deliveryFiscalYear) =>
      filter.nfcDeliveryFiscalYears.includes(deliveryFiscalYear.year),
    )
  const filterJCreditAllProducts = greenIndex.jcreditAllProducts.filter(
    (product) => filter.jCreditProductTypes.includes(product.id),
  )

  const nfcSummaryModel = convertNFCGreenIndexSummaryModel(
    greenIndex.summary,
    filterNFCAllProducts,
    filterNFCAllDeliveryFiscalYear,
  )

  const nfcSummaryModelV2 = convertNFCGreenIndexSummaryModelV2(
    greenIndex.summary,
    filterNFCAllProducts,
    filterNFCAllDeliveryFiscalYear,
  )

  const nfcGraphModel = convertNFCGreenIndexGraphModel(
    greenIndex.nfcIndexPrices,
    filterNFCAllProducts,
    filterNFCAllDeliveryFiscalYear,
  )

  const jCreditSummaryModel = convertJCreditGreenIndexSummaryModel(
    greenIndex.summary,
    filterJCreditAllProducts,
  )

  const jCreditGraphModel = convertJCreditGreenIndexGraphModel(
    greenIndex.jcreditIndexPrices,
    filterJCreditAllProducts,
  )

  return {
    nfc: {
      summary: nfcSummaryModel,
      summaryV2: nfcSummaryModelV2,
      graph: nfcGraphModel,
      allProducts: filterNFCAllProducts,
    },
    jCredit: {
      summary: jCreditSummaryModel,
      graph: jCreditGraphModel,
      allProducts: filterJCreditAllProducts,
    },
  }
}
