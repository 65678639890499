import * as React from 'react'
import { FC, ReactNode } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import { NavigationBar, Spacing, useTheme } from '@enechain/ecloud-designsystem'
import { JcexHorizontal } from '@enechain/ecloud-icons'

import OrganizationProfileHeader from './OrganizationProfileHeader'
import { MainLayoutLoaderData } from './loader'

export type DrawerLayoutProps = {
  sideMenu: ReactNode
  version?: string
  children: ReactNode
}

const SIDE_MENU_WIDTH = 72
const SUPPORT_PAGE_URL = 'https://www.japan-climate-exchange.com/support'

const MainLayout: FC<DrawerLayoutProps> = ({
  sideMenu,
  version,
  children,
}: DrawerLayoutProps) => {
  const theme = useTheme()
  const { name, src } = useLoaderData() as MainLayoutLoaderData

  const { t } = useTranslation('common')
  const { logout, user } = useAuth0()

  const onClickSignOut = (): void => {
    void (async (): Promise<void> => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    })()
  }

  return (
    <Flex
      position="fixed"
      top={Spacing[0]}
      right={Spacing[0]}
      bottom={Spacing[0]}
      left={Spacing[0]}
      width="full"
      height="full"
      background={theme.semanticTokens.colors.Surface.primary}
    >
      <Box
        position="fixed"
        top={Spacing[0]}
        bottom={Spacing[0]}
        width={SIDE_MENU_WIDTH}
        height="full"
        marginTop={{ base: 0, md: Spacing[12] }}
        backgroundColor={theme.semanticTokens.colors.Surface.secondary}
        paddingX={Spacing[4]}
        paddingY={Spacing[2]}
      >
        <Flex flexDirection="column" gap={Spacing[4]} paddingY={Spacing[4]}>
          <OrganizationProfileHeader name={name} src={src} />
          {sideMenu}
        </Flex>
      </Box>

      <NavigationBar
        sx={{
          paddingX: '10px',
          backgroundColor: theme.semanticTokens.colors.Surface.primary,
          borderBottom: `1px solid ${theme.semanticTokens.colors.Border.midEmphasis}`,
        }}
        logo={<JcexHorizontal width="80px" height="28px" />}
        menuItems={[
          {
            title: t('label.sign_out'),
            click: onClickSignOut,
          },
        ]}
        onOpen={(): void => console.log('mobile open')}
        supportButtonStyles={{
          'aria-label': 'support page button',
          fill: theme.semanticTokens.colors.Object.disabled,
          _hover: {
            backgroundColor: theme.semanticTokens.colors.Hover.lowEmphasis,
          },
          _active: {
            backgroundColor: theme.semanticTokens.colors.Hover.lowEmphasis,
          },
        }}
        supportPageUrl={SUPPORT_PAGE_URL}
        userMailAddress={user?.email ?? ''}
        userName={user?.name ?? ''}
        version={version}
      />

      <Box
        position="absolute"
        zIndex="-1"
        top={Spacing[0]}
        right={Spacing[0]}
        bottom={Spacing[0]}
        left={Spacing[0]}
        width="100%"
        height="100%"
        paddingTop={Spacing[12]}
        paddingLeft={SIDE_MENU_WIDTH}
      >
        <Box position="relative" overflowY="auto" width="full" height="full">
          {children}
        </Box>
      </Box>
    </Flex>
  )
}

export default React.memo(MainLayout)
