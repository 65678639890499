import * as React from 'react'

import { Cell, flexRender } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Box, Flex, Stack, Text } from '@chakra-ui/react'

import {
  ColumnContent,
  DataTable,
  Spacing,
  Td,
} from '@enechain/ecloud-designsystem'

import { ChangeBadge } from '~/trader/pages/CarbonIndex/components/ChangeBadge'
import { LatestPriceHeader } from '~/trader/pages/CarbonIndex/components/LatestPriceHeader'
import { usePriceTable } from '~/trader/pages/CarbonIndex/hooks/usePriceTable'
import {
  LatestPriceModel,
  LatestPriceValue,
  NFCGreenIndexSummaryModelV2,
} from '~/trader/pages/CarbonIndex/models/indexSummary/nfcV2'
import { Maybe } from '~/trader/types/AdvancedTypes'

type Props = {
  data: Maybe<NFCGreenIndexSummaryModelV2>
  displayDeliveryFiscalYears: number[]
}

export const NFCPriceContentsV2: React.FC<Props> = ({
  data,
  displayDeliveryFiscalYears,
}) => {
  const { t } = useTranslation(['common', 'domain', 'trader'])

  const { averagePriceTable, latestPriceTable } = usePriceTable({
    data,
    displayDeliveryFiscalYears,
  })

  if (data === undefined) {
    return null
  }

  return (
    <>
      <ColumnContent
        flexSize={0}
        label={t('trader:page.carbon_index.title_average_price')}
      >
        {displayDeliveryFiscalYears.length === 0 ? (
          <Text>{t('common:error.empty_data')}</Text>
        ) : (
          <Box width="fit-content">
            <DataTable table={averagePriceTable} />
          </Box>
        )}
      </ColumnContent>
      <Stack spacing={Spacing[2]}>
        <LatestPriceHeader date={data.latestReferenceDate} />
        {displayDeliveryFiscalYears.length === 0 ? (
          <Text>{t('common:error.empty_data')}</Text>
        ) : (
          <Box width="fit-content">
            <DataTable
              renderCell={(
                cell: Cell<LatestPriceModel, LatestPriceValue>,
              ): JSX.Element => (
                <Td key={cell.id} sx={cell.column.columnDef.meta?.cellStyle}>
                  {cell.column.id === 'productType' ? (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  ) : (
                    <Flex
                      alignItems="center"
                      justifyContent="end"
                      gap={Spacing[2]}
                    >
                      {cell.getValue() !== undefined ? (
                        <>
                          <Text>{cell.getValue().price}</Text>
                          <ChangeBadge
                            changeAmount={cell.getValue().changeAmount}
                            changeRate={cell.getValue().changeRate}
                            isNull={cell.getValue().isNull}
                          />
                        </>
                      ) : (
                        <>
                          <ChangeBadge changeAmount="0" changeRate={0} isNull />
                        </>
                      )}
                    </Flex>
                  )}
                </Td>
              )}
              table={latestPriceTable}
            />
          </Box>
        )}
      </Stack>
    </>
  )
}
