import { FeatureFlagSetting } from './types'

export const DARK_MODE = 'DARK_MODE' as const
export const SANDBOX = 'SANDBOX' as const
export const CARBON_INDEX_TABLE_PRICE = 'CARBON_INDEX_TABLE_PRICE' as const

export const defaultFlags: FeatureFlagSetting[] = [
  {
    name: DARK_MODE,
    description: '24/09 ~ 24/10',
    active: false,
  },
  {
    name: SANDBOX,
    description: '24/09 ~ 24/10',
    active: false,
  },
  {
    name: CARBON_INDEX_TABLE_PRICE,
    description: '24/10 ~ 24/11',
    active: false,
  },
]
